import { queryClient } from "config"
import { debounce } from "lodash"

import { Icons } from "../Icons"

const orderDebaunce = debounce((from) => {
  if (from.includes("new")) if (queryClient.isFetching() === 0) window.refetchNewOrders()
}, 700)

export const user = [
  {
    permission: "user_list",
    to: "/user/",
    label: "Foydalanuvchilar",
    icon: <Icons.UserIcon />,
  },
  {
    permission: "user_group_list",
    to: "/user/group",
    label: "Guruhlar",
    icon: <Icons.Group />,
  },
  {
    permission: "user_team_list",
    to: "/user/team",
    label: "Jamoalar",
    icon: <Icons.List />,
  },
  {
    permission: "transaction_list",
    to: "/user/transaction",
    label: "Tranzaksiya  ",
    icon: <Icons.Category />,
  },
]

export const order = [
  {
    permission: "order_list",
    to: "/order/new",
    label: "Yangi",
    icon: <Icons.List />,
    preventSearchParams: true,
    onClick: ({ from }) => {
      orderDebaunce(from)
    },
  },
  {
    permission: "order_list",
    to: "/order/all",
    label: "Hammasi",
    icon: <Icons.ListOL />,
    preventSearchParams: true,
  },
  {
    permission: "order_list",
    to: "/order/accepted",
    label: "Dastavkaga tayyor",
    icon: <Icons.Check />,
    preventSearchParams: true,
  },
  {
    permission: "order_list",
    to: "/order/sent",
    label: "Yolda",
    icon: <Icons.Taxi />,
    preventSearchParams: true,
  },
  {
    permission: "order_list",
    to: "/order/delivered",
    label: "Yetqazildi",
    icon: <Icons.Truck style={{ width: "25px" }} />,
    preventSearchParams: true,
  },
  {
    permission: "order_list",
    to: "/order/pending",
    label: "Qayta qongiroq",
    icon: <Icons.Clock />,
    preventSearchParams: true,
  },
  {
    permission: "order_list",
    to: "/order/cancelled",
    label: "Qaytib keldi",
    icon: (
      <Icons.XLight
        style={{ minWidth: "13px", width: "10px", marginRight: "5px", marginLeft: "2px" }}
      />
    ),
    preventSearchParams: true,
  },
  {
    permission: "order_list",
    to: "/order/archived",
    label: "Arxiv",
    icon: <Icons.BoxArchive />,
    preventSearchParams: true,
  },
  {
    permission: "order_list",
    to: "/order/spam",
    label: "Spam",
    icon: <Icons.Spam />,
    preventSearchParams: true,
  },
  {
    permission: "order_list",
    to: "/order/hold",
    label: "Hold",
    icon: <Icons.Anchor />,
    preventSearchParams: true,
  },
  {
    permission: "product_refund_list",
    to: "/order/refund",
    label: "Pul qaytarishlar",
    icon: <Icons.List />,
    preventSearchParams: true,
  },
]

export const profile = [
  {
    permission: "profile",
    to: "/profile/",
    label: "Profil",
    icon: <Icons.Group />,
  },
  {
    permission: "profile",
    to: "/profile/withdraw",
    label: "Pul yechish",
    icon: <Icons.Cash />,
  },
  {
    permission: "profile",
    to: "/profile/my-team",
    label: "Mening Jamoam",
    icon: <Icons.Group />,
  },
]

export const dashboard = [
  {
    permission: "product_list",
    to: "/product",
    label: "Maxsulot",
    icon: <Icons.List />,
  },
  {
    permission: "product_post_list",
    to: "/video-post",
    label: "Video post",
    icon: <Icons.List />,
  },
  {
    permission: "brand_list",
    to: "/brand",
    label: "Brand",
    icon: <Icons.Brand />,
  },
  {
    permission: "category_list",
    to: "/category",
    label: "Kategoriya",
    icon: <Icons.Category />,
  },
  {
    permission: "withdraw_list",
    to: "/withdraw",
    label: "Pul yechish",
    icon: <Icons.Cash />,
  },
  {
    permission: "stream_list",
    to: "/stream",
    label: "Oqim",
    icon: <Icons.ChartArea />,
  },
  {
    permission: "review_list",
    to: "/review",
    label: "Izohlar",
    icon: <Icons.Comments />,
  },
  {
    permission: "location_list",
    to: "/location",
    label: "Lokatsiya",
    icon: <Icons.Globe />,
  },
  {
    permission: "penalty_list",
    to: "/penalty",
    label: "Jarimalar",
    icon: <Icons.List />,
  },
  {
    permission: "bot_user_list",
    to: "/bot-users",
    label: "Bot foydalanuvchilari",
    icon: <Icons.List />,
  },
  {
    permission: "account_list",
    to: "/accounts",
    label: "Accountlar",
    icon: <Icons.List />,
  },
  {
    permission: "faq_list",
    to: "/question-answer",
    label: "Savol - Javob",
    icon: <Icons.List />,
  },
]

export const settings = [
  {
    permission: "admin_config_list",
    to: "/settings",
    label: "Sozlamalar",
    icon: <Icons.Settings />,
  },
]

export const store = [
  {
    permission: "store_list",
    to: "/store/",
    label: "Magazin",
    icon: <Icons.Store />,
  },
  {
    permission: "income_list",
    to: "/store/income",
    label: "Kirim",
    icon: <Icons.Calculator style={{ minWidth: "16px", width: "16px" }} />,
  },
  {
    permission: "product_outgoing_list",
    to: "/store/outgoing",
    label: "Chiqim",
    icon: <Icons.Calculator style={{ minWidth: "16px", width: "16px" }} />,
  },
  {
    permission: "store_withdraw_list",
    to: "/store/withdraw",
    label: "Pul yechish",
    icon: <Icons.Cash />,
  },
  {
    permission: "seller_sale_list",
    to: "/store/sale",
    label: "Sotuv",
    icon: <Icons.Sale />,
  },
  {
    permission: "revision_list",
    to: "/store/revision",
    label: "Reviziya",
    icon: <Icons.History />,
  },
]

export const ticket = [
  {
    permission: "ticket_list",
    to: "/ticket",
    label: "Ticket",
    icon: <Icons.Ticket />,
  },
  {
    permission: "ticket_list",
    to: "/ticket-label",
    label: "Teg",
    icon: <Icons.Tag />,
  },
  {
    permission: "ticket_list",
    to: "/ticket-category",
    label: "Kategoriya",
    icon: <Icons.Category />,
  },
]

export const logistics = [
  {
    permission: "logistic_storage_list",
    to: "/logistics/",
    label: "Filiallar",
    icon: <Icons.LocationMark className="logistics" />,
  },
  {
    permission: "order_waybill_list",
    to: "/logistics/waybills",
    label: "Listlar",
    icon: <Icons.Clipboard />,
  },
  {
    permission: "storage_sales_list",
    to: "/logistics/storage-sales",
    label: "Ko'chaga sotuvlar",
    icon: <Icons.Sale />,
  },
  {
    permission: "hold_product_list",
    to: "/logistics/hold-product",
    label: "Filialdan kirim",
    icon: <Icons.Calculator style={{ minWidth: "16px", width: "16px" }} />,
  },
  {
    permission: "warehouse_list",
    to: "/logistics/warehouse",
    label: "Omborxonalar",
    icon: <Icons.Brand />,
  },
  {
    permission: "pickup_list",
    to: "/logistics/pickup",
    label: "PVZlar",
    icon: <Icons.LocationMark className="logistics" />,
  },
]

export const marketing = [
  {
    permission: "promotion_list",
    to: "/marketing/",
    label: "Aksiyalar",
    icon: <Icons.Bitcoin style={{ minWidth: "16px", width: "16px" }} />,
  },
  {
    permission: "banner_list",
    to: "/marketing/banner",
    label: "Bannerlar",
    icon: <Icons.List />,
  },
  {
    permission: "contest_list",
    to: "/marketing/contest",
    label: "Konkurs",
    icon: <Icons.List />,
  },
  {
    permission: "discount_list",
    to: "/marketing/discount",
    label: "Chegirmalar",
    icon: <Icons.Ticket />,
  },
  {
    permission: "news_list",
    to: "/marketing/news",
    label: "Yangiliklar",
    icon: <Icons.List />,
  },
  {
    permission: "coupon_list",
    to: "/marketing/coupon",
    label: "Kuponlar",
    icon: <Icons.Bitcoin style={{ minWidth: "16px", width: "16px" }} />,
  },
  {
    permission: "promo_code_list",
    to: "/marketing/promocode",
    label: "Promokodlar",
    icon: <Icons.Mastercard />,
  },
]
