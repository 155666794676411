import { get, reject } from "lodash"
import { useState } from "react"
import toast from "react-hot-toast"
import { notification } from "services"
import storage from "services/Storage"

function useCall() {
  const [isFetching, setIsFetching] = useState(false)

  const callToClient = ({ orderId }) => {
    if (!isFetching) {
      setIsFetching(true)
      const call = fetch(`${process.env.REACT_APP_API_BASE_URL}orders/${orderId}/make-call`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${storage.get("token")}`,
        },
      })
        .then(async (res) => {
          const data = await res.json()
          const message = get(data, "message", "Xatolik yuz berdi")

          setIsFetching(false)
          if (get(res, "status") >= 400) {
            notification.error(message)
          }
        })
        .catch(() => {
          setIsFetching(false)
        })

      toast.promise(call, {
        loading: "So'rov yuborilmoqda",
        success: "So'rov yuborildi",
        error: "Xatolik yuz berdi",
      })
    }
  }

  return { callToClient, isFetching }
}

export default useCall
