const { lazy } = require("react")

const Pages = {
  Profile: lazy(() => import("Pages/Profile/UpdateProfile")),
  ProfileWithdraw: lazy(() => import("Pages/ProfileWithdraw")),
  ProfilePenaltyReply: lazy(() => import("Pages/Profile/PenaltyReply")),

  Signin: lazy(() => import("Pages/Auth/Signin")),

  UserList: lazy(() => import("Pages/User/List")),
  UserCreate: lazy(() => import("Pages/User/Create")),
  UserUpdate: lazy(() => import("Pages/User/Update")),

  GroupList: lazy(() => import("Pages/Group/List")),
  GroupCreate: lazy(() => import("Pages/Group/Create")),
  GroupUpdate: lazy(() => import("Pages/Group/Update")),

  MyTeamList: lazy(() => import("Pages/MyTeam/List")),
  UserTeamsList: lazy(() => import("Pages/UserTeams/List")),

  ProductList: lazy(() => import("Pages/Product/List")),
  ProductUpdate: lazy(() => import("Pages/Product/Update")),

  VideoPostList: lazy(() => import("Pages/VideoPost/List")),
  VideoPostCreate: lazy(() => import("Pages/VideoPost/Create")),
  VideoPostUpdate: lazy(() => import("Pages/VideoPost/Update")),

  BrandList: lazy(() => import("Pages/Brand/List")),
  BrandCreate: lazy(() => import("Pages/Brand/Create")),
  BrandUpdate: lazy(() => import("Pages/Brand/Update")),

  CategoryList: lazy(() => import("Pages/Category/List")),
  CategoryCreate: lazy(() => import("Pages/Category/Create")),
  CategoryUpdate: lazy(() => import("Pages/Category/Update")),
  CategorySort: lazy(() => import("Pages/Category/Sort")),

  WithdrawList: lazy(() => import("Pages/Withdraws/List")),
  WithdrawView: lazy(() => import("Pages/Withdraws/View")),

  StoreWithdrawList: lazy(() => import("Pages/StoreWithdraws/List")),
  StoreWithdrawView: lazy(() => import("Pages/StoreWithdraws/View")),

  TransactionList: lazy(() => import("Pages/Transaction/List")),

  IncomeList: lazy(() => import("Pages/Income/List")),
  IncomeCreate: lazy(() => import("Pages/Income/Create")),
  IncomeUpdate: lazy(() => import("Pages/Income/Update")),

  OutgoingList: lazy(() => import("Pages/Outgoing/List")),
  OutgoingCreate: lazy(() => import("Pages/Outgoing/Create")),

  StreamList: lazy(() => import("Pages/Stream/List")),
  StreamUpdate: lazy(() => import("Pages/Stream/Update")),

  CouponList: lazy(() => import("Pages/Coupon/List")),
  CouponCreate: lazy(() => import("Pages/Coupon/Create")),
  CouponUpdate: lazy(() => import("Pages/Coupon/Update")),

  PromocodeList: lazy(() => import("Pages/Promocode/List")),
  PromocodeCreate: lazy(() => import("Pages/Promocode/Create")),
  PromocodeUpdate: lazy(() => import("Pages/Promocode/Update")),

  RevisionList: lazy(() => import("Pages/Revision/List")),
  RevisionCreate: lazy(() => import("Pages/Revision/Create")),
  RevisionView: lazy(() => import("Pages/Revision/View")),

  ReviewList: lazy(() => import("Pages/Review/List")),
  ReviewCreate: lazy(() => import("Pages/Review/Create")),
  ReviewUpdate: lazy(() => import("Pages/Review/Update")),

  QuestionAnswerList: lazy(() => import("Pages/QuestionAnswer/List")),
  QuestionAnswerCreate: lazy(() => import("Pages/QuestionAnswer/Create")),
  QuestionAnswerUpdate: lazy(() => import("Pages/QuestionAnswer/Update")),

  SaleList: lazy(() => import("Pages/Sale/List")),

  OrderList: lazy(() => import("Pages/Order/List")),
  OrderCreate: lazy(() => import("Pages/Order/Create")),
  OrderUpdate: lazy(() => import("Pages/Order/Update")),

  LocationList: lazy(() => import("Pages/Location/List")),
  LocationCreate: lazy(() => import("Pages/Location/Create")),
  LocationUpdate: lazy(() => import("Pages/Location/Update")),

  StoreList: lazy(() => import("Pages/Store/List")),
  StoreCreate: lazy(() => import("Pages/Store/Create")),
  StoreUpdate: lazy(() => import("Pages/Store/Update")),

  Settings: lazy(() => import("Pages/Settings")),

  Statistics: lazy(() => import("Pages/Statistics")),

  BannerList: lazy(() => import("Pages/Banner/List")),
  BannerCreate: lazy(() => import("Pages/Banner/Create")),
  BannerUpdate: lazy(() => import("Pages/Banner/Update")),

  TicketList: lazy(() => import("Pages/Ticket/List")),
  TicketLabelList: lazy(() => import("Pages/Ticket/LabelList")),
  TicketCategoryList: lazy(() => import("Pages/Ticket/CategoryList")),
  TicketUpdate: lazy(() => import("Pages/Ticket/Update")),

  NewsList: lazy(() => import("Pages/News/List")),
  NewsCreate: lazy(() => import("Pages/News/Create")),
  NewsUpdate: lazy(() => import("Pages/News/Update")),

  ContestList: lazy(() => import("Pages/Contest/List")),
  ContestCreate: lazy(() => import("Pages/Contest/Create")),
  ContestUpdate: lazy(() => import("Pages/Contest/Update")),

  StorageList: lazy(() => import("Pages/Storage/List")),
  StorageCreate: lazy(() => import("Pages/Storage/Create")),
  StorageUpdate: lazy(() => import("Pages/Storage/Update")),

  PickupList: lazy(() => import("Pages/Pickup/List")),
  PickupCreate: lazy(() => import("Pages/Pickup/Create")),
  PickupUpdate: lazy(() => import("Pages/Pickup/Update")),

  WaybillsList: lazy(() => import("Pages/Waybills")),
  WaybillsUpdate: lazy(() => import("Pages/Waybills/Update")),

  RefundList: lazy(() => import("Pages/Refund/List")),
  RefundView: lazy(() => import("Pages/Refund/View")),

  StorageHoldProductList: lazy(() => import("Pages/StorageHoldProduct/List")),

  PenaltyList: lazy(() => import("Pages/Penalty/List")),
  PenaltyView: lazy(() => import("Pages/Penalty/PenaltyView")),

  DiscountList: lazy(() => import("Pages/Discount/List")),

  IncomeFromStorage: lazy(() => import("Pages/StorageSale/List")),

  WarehouseList: lazy(() => import("Pages/Warehouse/List")),
  WarehouseCreate: lazy(() => import("Pages/Warehouse/Create")),
  WarehouseUpdate: lazy(() => import("Pages/Warehouse/Update")),

  PromotionList: lazy(() => import("Pages/Promotion/List")),
  PromotionCreate: lazy(() => import("Pages/Promotion/Create")),
  PromotionUpdate: lazy(() => import("Pages/Promotion/Update")),

  BotUsers: lazy(() => import("Pages/BotUsers/List")),
  Forbidden: lazy(() => import("Pages/Auth/Forbidden")),

  AccountList: lazy(() => import("Pages/Account/List")),

  AccountTransactionList: lazy(() => import("Pages/AccountTransactions")),
}

export default Pages
